.navbar { /* The actual <nav> element */
    display: flex;
    flex-wrap: wrap;
}

#branding {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
}

.nav-item { /* li */
    display: flex;
    align-items: center;
}

.App-logo {
	animation: App-logo-spin infinite 10s linear;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

nav {
	background-color: var(--nav-back);
	color: var(--text-color);
}

a {
	color: var(--link-color);
	text-decoration: none;
    transition-duration: 500ms;
    transition-property: color;
}

a:hover {
	color: var(--link-hover-color, var(--link-color));
	/* text-decoration: underline; */
}

.nav-link {
	color: inherit;
}

.nav-link:hover {
	color: inherit;
}

.nav-item {
    box-sizing: border-box;
	text-align: center;
	color: var(--text-color);
    box-sizing: border-box;
    border-bottom: 2px transparent solid;
}

.nav-item.active {
    border-bottom-color: #AA4;
}

.nav-item a:hover {
	color: var(--link-hover-color, var(--text-color));
}

@media screen and (max-width: 64em) {
    .navbar .nav-item {
        transition-property: all;
        transition: .25s;
        margin: 1em;
        font-size: inherit;
        flex: 1 0 100%;
    }

    .navbar.collapsed .nav-item {
        margin: 0;
        padding: 0;
        font-size: 0;
    }

    #branding {
        display: flex;
        justify-content: space-between;
        flex: 1 0 100%;
    }

    .nav-item {
        flex: 1 0 100%;
        justify-content: center;
        display: flex;
    }
}

@media screen and (min-width: 64em) {
    #branding .toggler {
        display: none;
    }

    .nav-item {
        margin: .5em .25em;
    }
}