:root {
	--body-background-color: rgba(25, 25, 25, 1);
	--nav-back: rgb(16, 38, 42);
	--background: rgb(47, 57, 72);
	--text-color: #ccc;
	--link-color: orange;
	--link-hover-color: orange;
	--line-break-back: #888;
}

html, body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	background-color: var(--body-background-color);
	color: var(--text-color);
}

#root {
    background-color: var(--body-background-color);
}

.container-fluid {
	margin-top: .5rem;
	background-color: var(--body-background-color);
	max-width: 1680px;
	margin: auto;
}

.card {
    margin: 1rem .75rem 0;
    border-radius: .5rem;
	background-color: var(--background);
    padding: 1em .75em;
    text-align: center;
}

.navbar-toggler {
	color: var(--text-color);
	border-color: var(--text-color);
}

hr {
	background-color: var(--line-break-back);
	border-color: var(--line-break-back);
}

.text-center {
    text-align: center;
}

footer {
	text-align: center;
	padding: 1em 0.75em;
	background-color: var(--nav-back);
	color: var(--text-color);
}

@media screen and (max-width: 64em) {
	.card {
        margin: 1rem 0 0;
        border-radius: 0;
    }
}
