.toggler {
    border-color: var(--toggler-border, #666);
    border-width: 2px;
    border-radius: .5em;
    border-style: solid;

    cursor: pointer;

    background-color: transparent;
    margin: 4px;
    padding: 0;
    display: flex;
    align-items: center;
}

.hamburger {
    height: 28px;
    width: 28px;

    stroke: #444;
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}